import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

/* Use this routes definition in case you want to make them lazy-loaded */
const routes: Routes = [
//     { path: 'tso', component: TsoComponent },
//     { path: 'dso', component: DsoComponent },
//     { path: 'cpo', component: CpoComponent },
//     { path: 'emsp', component: EmspComponent },
//     { path: 'aggregator', component: AggregatorComponent },
//     { path: 'admin', component: AdminComponent },
    { path: 'register', component: RegisterComponent }
];


@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDividerModule,
        NgxSpinnerModule,
        ZXingScannerModule,
    ],
    declarations: [
        // DsoComponent,
        RegisterComponent
    ],
    entryComponents: [],
    providers: [],
    exports: [
        RegisterComponent,
    ]
})

export class RegistrationModule { }
