import { constants } from './constants';

export const environment = {
  production: true,
  backendBaseUrl: 'https://web.ev-dashboard.energyweb.org/api',
  switchboardUrl: 'https://switchboard-dev.energyweb.org/',
  iam: {
    cacheServerUrl: 'https://identitycache-dev.energyweb.org/v1',
    rpcUrl: 'https://volta-internal-archive.energyweb.org',
    chainId: 73799,
    natsServerUrl: 'https://identityevents-dev.energyweb.org/'
  },
  ...constants
};
