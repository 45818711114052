import { Injectable } from '@angular/core';
import { menu } from '../../routes/menu';

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    currentUserRole: string;

    constructor() {
        this.menuItems = [];
    }

    buildMenu() {
        this.menuItems = [];
        this.addMenu(menu)
    }

    private addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>,
        showForRole?: Array<string>
    }>) {
        if (localStorage.getItem('currentUser')) {
            this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).organizationType;
            console.log(this.currentUserRole);
        }
        items.forEach((item) => {
            // default to showing the menu item for each role
            if (item.showForRole) {
                // show this item to users with the specified roles only
                if (item.showForRole.includes(this.currentUserRole)) {
                    this.menuItems.push(item);
                }
            } else {
                this.menuItems.push(item);
            }
        });
    }

    getMenu() {
        return this.menuItems;
    }

}
