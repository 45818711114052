import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuService } from 'src/app/core/menu/menu.service';

const swal = require('sweetalert');

const createFormGroupRegister = dataItem => new FormGroup({
  organizationType: new FormControl(dataItem.organizationType, Validators.required)
});

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public formGroupRegister: FormGroup;

  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    // redirect to home if already logged in
    if (this.authService.currentUser()) {
      this.router.navigate(['/']);
    }
  }

  onFormRegisterSubmit(): void {
    if (!this.formGroupRegister.invalid) {
      this.authService.signUp(this.formGroupRegister.value.organizationType)
        .then(() => {
          this.spinner.hide();
          swal('Registration pending. Once approved, you may sign in using Metamask.', '', 'info');
        })
        .catch(error  => {
            this.spinner.hide();
            swal('Could not open Switchboard enrolment page.', '', 'error');
        });
    }
  }

  onSignInSubmit(): void {
    this.spinner.show();
    this.authService.signIn()
      .then(userData => {
        this.authService.setUser(JSON.stringify(userData)).then(() => {
          this.menuService.buildMenu();
          this.spinner.hide();
          this.router.navigate(['/']).then(() => {
              swal('Successfully signed in.', '', 'success');
          });
        });
      })
      .catch(error => {
        console.log('got error:', error);
        this.spinner.hide();
        swal(error.message, '', 'error');
      });
  }

  ngOnInit() {
    this.formGroupRegister = createFormGroupRegister({});
  }

}
