import { Injectable } from '@angular/core';
import { IAM, WalletProvider } from 'iam-client-lib';
import { environment } from 'src/environments/environment';
import { IamService } from './iam.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private readonly iamService: IamService
    ) {
    }

    public async signUp(organizationType: string) {
        // redirect to relevant enrollment page
        console.log('redirecting to switchboard');
        const windowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        const url = `${environment.switchboardUrl}/#/enrol?app=${environment.namespace}&roleName=`
            + organizationType.toLowerCase();
        window.open(url, '_blank', windowFeatures);
    }

    public async signIn() {
        let did: string;
        try {
            await this.iamService.initialize();
            if (!this.iamService.iam.isConnected()) {
                throw Error();
            }
            did = this.iamService.iam.getDid();
        } catch (err) {
            throw Error('Could not connect to Metamask. Please ensure your acount is connected.');
        }

        console.log('did:', did);

        const didDocument = await this.iamService.iam.getDidDocument({ did, includeClaims: true });
        const claims = didDocument.service;
        const evdashboardClaims = claims.filter(c => {
            const claimType = c.claimType as string;
            if (claimType) {
                return claimType.includes(environment.namespace);
            } else {
                return false;
            }
        });

        if (evdashboardClaims.length === 0) {
            const pendingRequestsFromCacheServer = await this.iamService.iam.getClaimsByRequester({
                did,
                parentNamespace: environment.namespace
            });
            if (pendingRequestsFromCacheServer.length > 0) {
                throw Error('Enrolment request awaiting approval or addition to DID Document. Login to Switchboard to check its status.');
            }
            throw Error('No enrolment requests found for account. Please use sign up form.');
        }

        // take the first one: for different roles you should use another account!
        // note: later there could be an option to select role to sign in as
        const claim = evdashboardClaims[0];
        const type = claim.claimType as string;
        const organizationType = type.split('.').shift().toUpperCase();
        const name = claim.fields[0].value;
        return { did, name, organizationType };
    }

    /**
     * Returns the current user
     */
    public currentUser(): any {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    /**
     * Logout the user
     */
    public async logout() {
        await this.iamService.close();
        const promise = new Promise(function (resolve, reject) {
            try {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('EW-DID-CONFIG');
                resolve();
            } catch (e) {
                reject();
            }
        }.bind(this));
        return promise;
    }

    public getUserId(): string {
        return JSON.parse(localStorage.getItem('currentUser')).id;
    }

    public getDID(): string {
        return "";
    }

    public getRoles(): Array<string> {
        return [JSON.parse(localStorage.getItem('currentUser')).organizationType];
    }

    public isOwner(): boolean {
        return JSON.parse(localStorage.getItem('currentUser')).organizationType == "Asset-owner";
    }

    public isDSO(): boolean {
        return JSON.parse(localStorage.getItem('currentUser')).organizationType == "DSO";
    }

    public isTSO(): boolean {
        return JSON.parse(localStorage.getItem('currentUser')).organizationType == "TSO";
    }

    public isMSP(): boolean {
        return JSON.parse(localStorage.getItem('currentUser')).organizationType == "EMSP";
    }

    public isCPO(): boolean {
        return JSON.parse(localStorage.getItem('currentUser')).organizationType == "CPO";
    }

    public setUser(user: any) {
        const promise = new Promise(function (resolve, reject) {
            try {
                // console.log(JSON.parse(user).organizationType);
                // this.roles = [JSON.parse(user).organizationType];
                localStorage.setItem('currentUser', user);
                resolve();
            } catch (e) {
                reject();
            }
        }.bind(this));
        return promise;
    }


}

