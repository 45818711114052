
const Home = {
    text: 'Home',
    link: '/dashboard',
    icon: 'home-icon'
};

const VehiclesChargePoints = {
    text: 'Vehicles & Charge Points',
    link: '/charge-points',
    icon: 'vehicle-charge-point-icon',
    showForRole: ['TSO', 'DSO']
};

const ChargePoints = {
    text: 'Charge Points',
    link: '/charge-points',
    icon: 'charge-points-icon',
    showForRole: ['CPO']
};

const Vehicles = {
    text: 'Vehicles',
    link: '/charge-points',
    icon: 'car-menu-icon',
    showForRole: ['EMSP']
};

const Logout = {
    text: 'Logout',
    link: '/logout',
    icon: 'logout-icon'
};

export const menu = [
    Home,
    VehiclesChargePoints,
    Vehicles,
    ChargePoints,
    Logout
];
