import { Injectable } from '@angular/core';
import { IAM, WalletProvider, setCacheClientOptions, setMessagingOptions, MessagingMethod } from 'iam-client-lib';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IamService {
  // tslint:disable-next-line: variable-name
  private _iam: IAM | undefined;
  public get iam(): IAM | undefined {
    return this._iam;
  }

  // tslint:disable-next-line: variable-name
  private _cacheServerAvailable = false;
  public get cacheServerAvailable(): boolean {
    return this._cacheServerAvailable;
  }

  async initialize() {
    if (this.iam === undefined || !this.iam.isConnected()) {
      setMessagingOptions(environment.iam.chainId, {
        messagingMethod: MessagingMethod.Nats,
        natsServerUrl: environment.iam.natsServerUrl
      })
      setCacheClientOptions(environment.iam.chainId, {
        url: environment.iam.cacheServerUrl
      });
      const initialize = async () => this.iam.initializeConnection({ walletProvider: WalletProvider.MetaMask });

      try {
        this._iam = new IAM();
        await initialize();
        this._cacheServerAvailable = true;
      } catch {
        // set url to undefined as this is equivalent to disabling cacheserver
        setCacheClientOptions(environment.iam.chainId, {
          url: undefined
        });
        this._iam = new IAM();
        await initialize();
      }
    }
  }

  async close() {
    if (this.iam) {
      await this.iam.closeConnection();
    }
    this._iam = undefined;
  }
}
